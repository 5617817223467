import React from "react";
import {
  WebsiteSchema,
  SiteNavigationElementSchema,
  FoodEstablishmentSchema,
  Navigation,
  Footer,
  SocialMedia,
  SiteMap,
  CopyrightLabel,
  OutboundLink,
  FishermanBanner,
} from "@bluefin/components";
import { graphql, StaticQuery } from "gatsby";

import { getPageHeroImage } from "../utils/utils";
import "semantic-ui-less/semantic.less";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        fishermanBusiness {
          name
          type
          subtypes
          socialMedia {
            link
            type
          }
        }
        allFishermanBusinessWebsitePage(
          sort: { order: ASC, fields: navigationPriority }
        ) {
          nodes {
            slug
            description
            title
            pageType
            url
            navigationPriority
            groupName
            props
          }
        }
        allFishermanBusinessLocation {
          nodes {
            street
            city
            state
            zipCode
            country
            email
            phoneNumber
            name
            hours {
              open
              close
              day
            }
          }
        }
        fishermanBusinessWebsitePage(title: { eq: "Home" }) {
          components {
            fastId
            order
            files {
              file
              gatsbyFile {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    src
                    srcSet
                    aspectRatio
                    base64
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const {
        fishermanBusiness,
        allFishermanBusinessWebsitePage,
        allFishermanBusinessLocation,
        fishermanBusinessWebsitePage,
      } = data;

      return (
        <React.Fragment>
          <WebsiteSchema
            name={fishermanBusiness.name}
            url={"https://www.tcmchubbys.com"}
          />
          <SiteNavigationElementSchema
            pages={allFishermanBusinessWebsitePage.nodes}
            siteUrl={"https://www.tcmchubbys.com"}
          />
          <FoodEstablishmentSchema
            name={fishermanBusiness.name}
            siteUrl={"https://www.tcmchubbys.com"}
            businessType={fishermanBusiness.type}
            businessSubtypes={fishermanBusiness.subtypes}
            address={allFishermanBusinessLocation.nodes}
            images={getPageHeroImage({
              options: fishermanBusinessWebsitePage.components,
            })}
          />
          <div className={"site-container"}>
            <div>
              <Navigation
                links={allFishermanBusinessWebsitePage.nodes}
                bordered={false}
                centerMobileNavItems={true}
                fixed={false}
                header={fishermanBusiness.name}
                linksAs={"text"}
                primaryContentCentered={false}
              />
            </div>
            <div className={"page-container"}>{children}</div>
            <Footer
              backgroundColor={"primary"}
              horizontalAlign={"center"}
              verticalAlign={"middle"}
              columns={[
                <div />,
                <div>
                  <SocialMedia
                    socialMediaValues={fishermanBusiness.socialMedia}
                    buttonType={"circle"}
                    centerHeader={false}
                    colors={"secondary"}
                    groupButtons={false}
                    groupVertically={false}
                    header={""}
                    inverted={false}
                    labelPosition={"free"}
                    showLabels={false}
                  />
                  <SiteMap
                    links={allFishermanBusinessWebsitePage.nodes}
                    horizontal={true}
                    bulleted={false}
                  />
                  <CopyrightLabel
                    company={fishermanBusiness.name}
                    phrase={"All Rights Reserved"}
                    privacyPolicyLink={
                      <OutboundLink
                        to={
                          "https://fisherman-static.s3.amazonaws.com/public/__fisherman/Client+Website+Privacy+Policy.pdf"
                        }
                        label={"Privacy Policy"}
                        eventLabel={"copyright-privacy-policy"}
                      />
                    }
                  />
                </div>,
                <div />,
              ]}
            />
          </div>
          <FishermanBanner />
        </React.Fragment>
      );
    }}
  />
);
