export function getPageHeroImage({ options }) {
  const heroElementComponents = options.filter(
    ({ fastId }) => fastId === "HeroElement"
  );

  return heroElementComponents.length > 0 &&
    heroElementComponents[0].files.length > 0
    ? heroElementComponents[0].files.map(({ file, gatsbyFile, altText }) => {
        if (gatsbyFile) {
          return { ...gatsbyFile.childImageSharp, altText };
        }
        return file;
      })
    : null;
}

export function getPageGalleryImages({ options }) {
  const imageCollectionComponents = options.filter(
    ({ fastId }) => fastId === "ImageCollection"
  );

  return imageCollectionComponents.length > 0
    ? imageCollectionComponents[0].files.map(
        ({ file, gatsbyFile, altText }) => {
          if (gatsbyFile) {
            return { ...gatsbyFile.childImageSharp, altText };
          }
          return file;
        }
      )
    : [];
}
